import React from 'react'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Divider } from '@mui/material';
import Button from '../SelectValue/Button';
import Button2 from '../SelectValue/Button2';
import { Link } from 'react-router-dom';


function PassAssCom({name, date, time, link, link2}) {
  return (
    <div className=' bg-white my-3 p-3 rounded-xl'>
     <h3 className=' text-[#14013A] mb-2 text-[16px] font-bold'>{name}</h3>
        <Divider/>
        <div className=' mt-4 mb-2 flex gap-5'>
        <div className=' flex justify-center flex-col'>
            <p className=' font-[500] text-[14px]'><DateRangeOutlinedIcon className=' text-[#2E3CC2]'/> {date}</p>
        </div>
        <div className=' flex justify-center flex-col'>
            <p className=' font-[500] text-[14px]'><AccessTimeIcon className=' text-[#2E3CC2]'/> {time}</p>
        </div>
       
        </div>
        <Divider/>
        <div className=' flex my-3 gap-4'>
        <div>
        <Link to={link2}>
            <Button2
                name={'View question'}
            />
             </Link>
        </div>
        <div>
            <Link to={link}>
            <Button
                name={'View report'}
            />
            </Link>
           
        </div>
        </div>
    </div>
  )
}

export default PassAssCom