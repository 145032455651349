import React, { useEffect, useState } from 'react'
import GradImg from '../../Images/graSt.png'
import Lesson from '../../Images/lesson.svg'
import Course from '../../Images/course.svg'
import Button from '../../Components/SelectValue/Button'
import DashCom from './DashCom'
import T1 from '../../Images/tt1.svg'
import T2 from '../../Images/tt2.svg'
import T3 from '../../Images/tt3.svg'
import T4 from '../../Images/tt4.svg'
import { Link } from 'react-router-dom'
import getServices from '../../Services/get-services'
import Loading from '../../Components/SelectValue/Loading'


function MainDashboard() {
  const [noAss, setNoAss] = useState('0')
  const [maxScore, setMax] = useState('0')
  const [minScore, setMin] = useState('0')
  const [loading, setLoading] = useState(false)
  const details = localStorage.getItem('userdetailss')
  const user =  JSON.parse(details)
  useEffect(() =>{
    setLoading(true)
    getServices.getTeacherCount(user.teacherId).then(
     res =>{
        setLoading(false)
        if(res.data.statusCode === '200'){

         
           setNoAss(res.data.result.noOfAssessmentTaken)
           setMax(res.data.result.maxScore)
           setMin(res.data.result)
        }
     }   
    ).catch(
        error =>{
            console.log(error)
        }
    )
  },[user.teacherId])
 

  return (
    <div>
    <Loading
      open={loading}
    />
        <div className=' rounded-2xl mb-4 bg-[#C1D5F6] w-full p-5  sm:p-10 sm:flex gap-6'>
        <div className=' flex flex-col justify-center'>
        <h1 className=' text-[16px] sm:text-[32px] text-center sm:w-[80%] text-[#2E3CC2] font-bold'>Welcome to LectureSoft, Your 21st century teaching and learning support platform</h1>
        </div>
         
          <img src={GradImg} alt='Grade'/>
        </div>
        <div className=' grid md:grid-cols-5 grid-cols-2 gap-4'>
          <div className=' rounded-[15px] border border-[#F0F2F5] bg-white p-3 col-span-2 md:col-span-3'>
            {/* <p className=' text-[14px] font-bold text-center text-[#14013A] mb-4'>Choose an option to get started</p> */}
            <div className=' flex justify-center gap-4'>
              <div className=' rounded-lg   p-8  sm:py-20  flex justify-center bg-white'>
                <div className=' flex flex-col justify-center '>
                <div>
                  <img src={Lesson} alt='lesson' className=' mx-auto mb-3'/>
                  {/* <p className=' text-[500] text-[18px] text-center text-[#1D2939] mb-3'>Lesson <br></br> assessment</p> */}
                  <Link to='/assessment'>
                  <Button
                    name={'Start lesson assessment'}
                  />
                  </Link>
                 
                </div>
                </div>
              </div>
              {/* <div className=' rounded-lg border  p-8 py-14 border-[#F0F2F5] flex justify-center bg-white'>
                <div>
                  <img src={Course} alt='lesson' className=' mx-auto mb-3'/>
                  <p className=' text-[500] text-[18px] text-center text-[#1D2939] mb-3'>Course/program <br></br> assessment</p>
                  <Button
                    name={'Start course assessment'}
                  />
                </div>
              </div> */}
            </div>
          </div>
            <div className=' col-span-2 gap-4 grid sm:grid-cols-2'>
            <DashCom
              img={T1}
              header={'Test taken'}
              count={noAss}
            />
             <DashCom
              img={T2}
              header={'Highest score'}
              count={maxScore}
            />
             <DashCom
              img={T3}
              header={'Lowest scroe'}
              count={minScore?.minScore}
            />
             <DashCom
              img={T4}
              header={'Average score'}
              count={minScore?.averageScore}
            />
            </div>
        </div>

    </div>
  )
}

export default MainDashboard