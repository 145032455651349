import React from 'react'

function Button({name, onClick}) {
  return (
    <>
    <button 
      className=' bg-primary-color  hover:bg-primary-color1  text-[14px] px-5 py-2.5 border-none rounded-lg font-bold text-white'
      onClick={onClick}
      type='submit' 
     >{name}</button>   
    </>
  )
}

export default Button