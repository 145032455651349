import React from 'react'
import PassAss from '../../Components/Assessment/PassAss'

function Report() {
  return (
    <div>
    <PassAss/>
    </div>
  )
}

export default Report