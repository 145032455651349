import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import getServices from '../../Services/get-services';
import PastQuestionCom from '../../Components/Assessment/PastQuestion';
import Loading from '../../Components/SelectValue/Loading';

function PastQuestion() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const[result, setResult] = useState({})

    useEffect(() =>{
        setLoading(true)
        getServices.getSingleAssesstment(id).then(
            res =>{
               setLoading(false)
               if(res.data.statusCode === '200'){
                   setResult(res.data.result)
               }
            }   
           ).catch(
               error =>{
                   console.log(error)
               }
           )
    },[id])
  return (
    <div>
    <Loading
        open={loading}
    />
        <h1 className=' mb-3 text-3xl'>Past question</h1>
        <p className=' mb-3 '>Title: <span className=' font-bold'>{result?.title}</span></p>
        <p className=' mb-3 '>Date: <span className=' font-bold'>{result?.inserted_dt}</span></p>
        <PastQuestionCom
            questionData={result?.questionData}
        />
    </div>
  )
}

export default PastQuestion