import React, { useEffect, useState } from 'react'
import Inputtt from '../SelectValue/Inputtt'
import Button from '../SelectValue/Button'

function BasicInfo() {
    const details = localStorage.getItem('userdetailss')
    const user =  JSON.parse(details)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [institute, setInstitute] = useState('')
    const [position, setPosition] = useState('')
    const [program, setProgram] = useState('')
    const [country, setCountry] = useState('')
    // const [city, setCity] = useState('')

    useEffect(() =>{
        setFirstName(user?.firstName)
        setLastName(user?.lastName)
        setInstitute(user?.institution)
        setCountry(user?.country)
    },[user?.firstName,user?.lastName, user?.institution,user?.country ])
  return (
    <div className=' rounded-lg bg-white p-4'>
    <h1 className=' text-[#14013A] font-[600] text-[18px] mb-4'> Profile details </h1>
    <form>
    <div className=' grid sm:grid-cols-2 mb-3 gap-4'>
        <Inputtt
            label={'First name'}
            value={firstName}
            onChange={setFirstName}
        />
        <Inputtt
            label={'Last name'}
            value={lastName}
            onChange={setLastName}
        />
    </div>   
    <Inputtt
        label={'Institution'}
        onChange={setInstitute}
        value={institute}
    />
    {/* <Inputtt
        label={'Position'}
        value={position}
        onChange={setPosition}
    />
    <Inputtt
        label={'programs'}
        value={program}
        onChange={setProgram}
    /> */}
    <Inputtt
        label={'Country'}
        value={country}
        onChange={setCountry}
    />
    {/* <Inputtt
        label={'City'}
        value={city}
        onChange={setCity}
    /> */}
    <div className=' my-4 flex justify-end'>
    <Button
        name={'Save'}
    />
    </div>
    </form>
   
    </div>
  )
}

export default BasicInfo