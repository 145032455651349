import React from 'react'

function Experience() {
  return (
    <div className=' rounded-lg bg-white p-4'>
    <h1 className=' text-[#14013A] font-[600] text-[18px] mb-4'> Experience </h1>

    </div>
  )
}

export default Experience