import React from 'react'

function Button2({onClick, name}) {
  return (
    <>
      <button 
      className=' bg-white hover:bg-white  text-[14px] px-5 py-2.5 border-2 border-[#EDEDF3] rounded-lg text-[#5C556C]'
      onClick={onClick}
      type='submit' 
     >{name}</button>   
    </>
  )
}

export default Button2