import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainDashboard from '../MainDashboard'
import Report from '../Report'
import Assessment from '../Assessment'
import Settings from '../Settings'
import BasicInfo from '../../Components/Settings/BasicInfo'
import About from '../../Components/Settings/About'
import Experience from '../../Components/Settings/Experience'
import NewAss from '../../Components/Assessment/NewAss'
import PassAss from '../../Components/Assessment/PassAss'
import ReportsDetials from '../../Components/Report/ReportsDetials'
import PastQuestion from '../Assessment/PastQuestion'

function Routess() {
  return (
    <div>
    <Routes>
    <Route path='/dashboard' element={<MainDashboard/>}/>
    <Route path='/history' element={<Report/>}/>
    <Route path='/history/report/:id' element={<ReportsDetials/>}/>
    <Route path='/history/pastques/:id' element={<PastQuestion/>}/>
    <Route path='/assessment' element={<Assessment/>}>
    <Route path=''  element={<NewAss/>}/>
     <Route path='newass' element={<NewAss/>}/>
      <Route path='passass' element={<PassAss/>}/>
    </Route>
    <Route path='/settings' element={<Settings/>}>
    <Route path='' element={<BasicInfo/>}/>
      <Route path='basicinfo' element={<BasicInfo/>}/>
      <Route path='aboutme' element={<About/>}/>
      <Route path='experience' element={<Experience/>}/>
    </Route>
    <Route path='*' element={<MainDashboard/>}/>
    </Routes>
       
    </div>
  )
}

export default Routess