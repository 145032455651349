import axios from "axios";
import authHeader from "./AuthHeader";
const BASEURL = 'https://api.lecturesoft.org/v1/api'


const login = (email, password) =>{
    const value = {"emailAddress" : email, "password": password}
   return axios.post(BASEURL + '/teacher_login', value, { headers: authHeader() })
}
const signup = (email, password, fName, lName, phone, country, insti) =>{
  const value = { "firstName" : fName,
  "lastName" : lName,
  "emailAddress": email,
  "phoneNumber" : phone,
  "country" : country,
  "institution" : insti,
  "password" :password}
 return axios.post(BASEURL + '/add_teacher', value,{ headers: authHeader() })
}
const answerAss = (data) =>{
   
  return axios.post(BASEURL + '/submit_assessment', data, { headers: authHeader() })
}

const forgetPass = (email) =>{
  const value = {"emailAddress" : email}
 return axios.post(BASEURL + '/forgot_password', value, { headers: authHeader() })
}

const resetPass = (token, newPass) =>{
  const value = {"token" : token,
  "newPassword" : newPass,
  "confirmPassword" : newPass}
 return axios.post(BASEURL + '/reset_password', value, { headers: authHeader() })
}

const changePass = (email, oldPass,newPass) =>{
  const value = {
    "emailAddress" : email,
    "oldPassword" : oldPass,
    "newPassword" : newPass
 }
 return axios.post(BASEURL + '/change_teacher_default_password', value, { headers: authHeader() })
}



const postServices = {
    login, 
    answerAss,
    signup,
    forgetPass,
    resetPass,
    changePass
}

export default postServices