
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import ResetUserLogin from './Pages/Login/ResetUserLogin';
import VerifyAccountForm from './Components/Signup/Verifyform';
import AdminPassReset from './Pages/Signup/AdminPassReset';


const Logout = React.lazy(() => import('./Pages/logout'));
class App extends React.Component {

  constructor(props) {
    super(props);
    // var token = localStorage.getItem('token');
   
    const details = localStorage.getItem('accountStatusss')
    
    if(details === null){
      this.loggedIn = false;
     
    }else{
      this.loggedIn = true;
      // setTimeout(this.logout, 100000);
     
    }
  }

  logout() {
    // localStorage.removeItem('token');

    localStorage.removeItem('userdetailss');
    localStorage.removeItem('accountStatusss');
    this.loggedIn = false;
    window.location.href = '/';
  }
    render() {
    return this.loggedIn === true? (
      <div>
      <BrowserRouter>
      <Routes>
        
        <Route path='/logout' element={ <Logout /> } />
        <Route path='/resetuserpassword' element={ <ResetUserLogin /> } />
        <Route path='*' element={<Dashboard/>}/>
      </Routes>

      
      </BrowserRouter>  
      </div>
    ): (
      <div>
      <BrowserRouter>
   
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/resetadminuserpassword' element={ <AdminPassReset /> } />
        <Route path='/resetuserpassword' element={ <ResetUserLogin /> } />
        <Route path='/signup' element={<Signup/>}/>
        <Route path='/forgetpassword' element={<VerifyAccountForm/>}/>
        <Route path='*' element={<Login/>}/>
      </Routes>
      </BrowserRouter>  
      </div>
    )
      ;
  }
}

export default App;
