import  React, { useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Logo from '../../Images/ls.png'
import Dashboardd from '../../Images/dash.svg'
import Ass from '../../Images/note.svg'
import Report from '../../Images/res.svg'
import Setttings from '../../Images/sett.svg'
import Loogo from '../../Images/log.svg'
import X from '../../Images/x.svg'
import Fcee from '../../Images/faa.svg'
import Lin from '../../Images/in.svg'
import IG  from '../../Images/ig.svg'
import { Link, NavLink, useLocation} from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LogoutIcon from '@mui/icons-material/Logout';
import Routess from './Routess';
import Mail from '../../Images/con.svg'
import Button2 from '../../Components/SelectValue/Button2';

const drawerWidth = 180;





function Dashboard(props) {
    const { window } = props;
    const details = localStorage.getItem('userdetailss')
    const user =  JSON.parse(details)
    const location = useLocation();
    console.log(location)
    const [open, setOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  
  const sidebar = [
    {
        name: 'Dashboard',
        icon: (
            Dashboardd
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    {
      name: 'Assessment',
      icon: (
        Ass
      ),
      link: '/assessment',
      id: 'ass',
  },
    {
        name: 'History',
        icon: (
          Report
        ),
        link: '/history',
        id: 'res',
    },
    {
        name: 'Settings',
        icon: (
          Setttings
        ),
        link: '/settings',
        id: 'setting',
    },
   
  ]
 

 
 

 
  const drawer = (
    <div className=' bg-[#FFFFFF] h-full'>
     <div className='  border-b border-b-gray-300 mb-2  bg-[#FFFFFF] '>
        <img src={Logo}  alt='logo' className=' w-full  '/>
        
          </div>
     
      <List>
          {sidebar.map((text, index) => (
            <NavLink
             className={({isActive}) =>{
                            return( ' block text-black p-3 mb-3 pl-6 ' + (
                              !isActive ? ' block ' : '  border-l-4 bg-[#08081A26]  border-l-primary-color text-back-color2 block'
                            ))
                        }}  
                      to={text.link}>
              <img src={text.icon} className=' inline-block' alt={text.name}/> 
              <span className=' pl-3'>{text.name}</span>
           
            </NavLink>
           
            
          ))}
          
        </List>
      <p className='text-center text-red-400 pr-12'><Link to='/logout'> <img src={Loogo} className=' mx-auto inline-block' alt={'logout'}/> Logout </Link></p>

      <div className=' mt-32 p-3'>
      <img src={Mail} alt='Mail' className=' mx-auto'/>
       <p className=' text-center text-[14px] font-[500]'>Contact LectureSoft Admin for all your questions</p>
       <div className=' flex justify-center mt-3'>
       <Button2 
        name={"Get in touch"}
       />
       </div>
       
      </div>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
   
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0.5}
       className=' '
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          
         backgroundColor: '#FFFFFF',
        }}
      >
      <div className=' flex justify-between'>
      <div className=' flex justify-between'>
      <Toolbar>
      <IconButton
            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' , color:'black'} }}
          >
            <MenuIcon />
          </IconButton>
         
                
       
        </Toolbar>
        <div className=' flex flex-col justify-center'>
        <p className=' text-black md:text-[24px] capitalize font-[400]'> {location.pathname === "/dashboard" ? "Welcome back":location.pathname === "/history"? 'History': location.pathname ==="/question"? 'Question':location.pathname ==="/assessment"? 'Assessment':location.pathname === "/settings"? 'Settings':""}  {user.username}</p>
        
         </div>
      </div>
     
            <div className=' md:flex hidden justify-center gap-2'>
            {/* <img src={Noti1} className=' inline-block' width='45'  alt='buy'/> */}
            {/* <img src={Noti2} className=' inline-block'  width='30'   alt='buy'/> */}
            <div className=' mt-3 flex gap-2 mr-3 relative cursor-pointer' onClick={() => setOpen(!open)}>
            <div>
            <p className=' px-3 py-2 rounded-[44px] bg-black text-white'>{user?.firstName[0]}</p> 
            </div>
           
            <div>
            <p className=' text-black inline-block text-[16px] mr-4'> {user?.firstName} .{user?.lastName[0]??''} </p>
            <p className=' text-black text-[12px]'>{user.emailAddress}</p>
            </div>
            <div className=' pt-2'>
            {open ?<ArrowDropUpIcon className='  text-primary-color'/> :<ArrowDropDownIcon className='  text-primary-color'/>} 
              {open && <div className=' mt-3 absolute top-12 right-0 rounded-lg p-6 pr-16 shadow-md text-back-color2 bg-white'>
              

                <p className=' mb-4 text-red-400'><Link to='/logout'><LogoutIcon/> <span>Logout</span></Link></p>

              </div>}
            </div>
               
            </div>
                    </div>
                 </div>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#FFFFFF' },
          }}
        >
          {drawer}
          

        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,  backgroundColor: '#FFFFFF' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      <div className=' w-full min-h-[80vh] bg-[#F0F2F5] p-6 overflow-y-auto pt-5' >
          <Routess/>
      </div>
       <div className=' p-3 border-t  pt-5 flex justify-between gap border-[#F0F2F5] '>
        <p>©2024 Lecture soft</p>
        <div className=' flex gap-3'>
          <img src={IG} alt='IG'/>
          <img src={X} alt='X'/>
          <img src={Lin} alt='IG'/>
          <img src={Fcee} alt='facebook'/>
        </div>
       </div> 
      </Box>
    </Box>
    </div>
  )
}
Dashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

export default Dashboard