import axios from "axios";
import authHeader from "./AuthHeader";
const BASEURL = 'https://api.lecturesoft.org/v1/api'

const getQuestion = () =>{
    return axios.get(BASEURL + `/list_all_question_by_group`, { headers: authHeader() })
}

const getPastHistory = (id) =>{
    return axios.get(BASEURL + `/list_assessment?teacherId=${id}`, { headers: authHeader() })
}

const getSingleAssesstment = (id) =>{
    return axios.get(BASEURL + `/single_teacher_assessment?assessmentId=${id}`, { headers: authHeader() })
}
const getCountries = () =>{
    return axios.get('https://restcountries.com/v3.1/all')
}

const getTeacherCount = (id) =>{
    return axios.get(BASEURL + `/teacher_count?teacherId=${id}`, { headers: authHeader() })
}
const getServices = {
    getQuestion,
    getPastHistory,
    getSingleAssesstment,
    getCountries,
    getTeacherCount
}

export default getServices