import React, { useState } from 'react'
import Textarea from '../SelectValue/TextArea'
import Button from '../SelectValue/Button'

function About() {
    const [bio, setBio] = useState('')
    const onSubmit = (e) =>{
        e.preventDefault()
    }
  return (
    <div className=' rounded-lg bg-white p-4'>
        <h1 className=' text-[#14013A] font-[600] text-[18px] mb-4'> About me </h1>
        <form onSubmit={onSubmit}>
            <Textarea
                label={"Bio"}
                value={bio}
                onChange={setBio}
            />

            <div className=' flex mt-5 justify-end'>
                <Button
                    name={'Save'}
                />
            </div>
        </form>
    </div>
  )
}

export default About