import React from 'react'

function DashCom({img, header, count}) {
  return (
    <div className=' border border-[#F0F2F5] bg-white rounded-[15px] p-3 px-10 pt-4'>
        <img src={img} className=' mb-4' alt={header}/>
        <p className=' text-[#7A8699] font-[400] text-[14px] mb-2'>{header}</p>
        <p className='font-[700] text-[#1D2939] text-[28px] mb-2'>{count ==null ?0 :count}</p>
    </div>
  )
}

export default DashCom