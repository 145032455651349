import React, {useEffect, useState} from 'react'
import Logo from '../../Images/lecture.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import postServices from '../../Services/post-services';
import Loading from '../../Components/SelectValue/Loading';
import Button from '../../Components/SelectValue/Button';
import Inputtt from '../../Components/SelectValue/Inputtt';



function ResetUserLogin() {
    const [open, setOpen] = useState(false)
    const [pin, setPin] =useState('')
    const [pass, setPass] =useState('')
    const email = localStorage.getItem('emaill')
//    let user
//    user = localStorage.getItem('user')
//     let email = sessionStorage.getItem('sEmail')
//     const userValue =  JSON.parse(user)

     useEffect(() =>{
        if(email.length >0){
            postServices.forgetPass(email).then(
                (response) => {
                    console.log(response.data)
                    setOpen(false)
                   if(response.data.statusCode === '200'){
                    
                    
                   }else{
                    toast.error(response.data.message)
                   setTimeout(() => {
                    window.location.reload()
                  }, 5000);
                   
                   }
                   
                  },
                  (error) => {
                    setOpen(false)
                    toast.error('Email validation failed')
                    setTimeout(() => {
                     window.location.reload()
                   }, 5000);
                     
                    }
              )
        }
       
     }, [email])

    
    const onSubmit = (e) =>{
      e.preventDefault()
          setOpen(true)  
          postServices.resetPass(pin,pass, pass).then(
            (response) => {
                console.log(response.data)
                setOpen(false)
               if(response.data.statusCode === '200'){
                 toast.success('Password reset successfully')  
                 setTimeout(() => {
                  window.location.href = '/login'
                }, 5000);
                
               }else{
                toast.error(response.data.message)
               setTimeout(() => {
                window.location.reload()
              }, 5000);
               
               }
               
              },
              (error) => {
                setOpen(false)
                toast.error('Password reset failed')
                setTimeout(() => {
                 window.location.reload()
               }, 5000);
                 
                }
          )
        
       
            // navigator('/createpin')
        console.log(pin)
    }
     
  return (
    <div className=' bg-signin-bg h-[95vh]'>
    <div className='grid h-[85vh] p-3 md:p-0 place-items-center'>
    <Loading
      open={open}
    />
    <ToastContainer/>
    <div className=' md:w-1/3 bg-white rounded-[16px] shadow-lg p-5 md:p-10'>
    <img src={Logo}  className=" mx-auto "  alt=" logo" /> 
        <h1 className=' pt-7 text-[18px] text-back-color2 mb-5 md:text-[32px] text-black font-[500]'>Please reset your password.</h1>

       <p className=' mb-3 text-[#44474E]'>A reset token has been sent to {email}. Enter the  code to verify your account.</p>
        <div className='  mt-5'>

        <div >
        <form onSubmit={onSubmit}> 
        <div className=' w-full '>
          <Inputtt
            label={"Reset token"}
            value={pin}
            onChange={setPin}
          />
           <Inputtt
            label={"New Password"}
            value={pass}
            onChange={setPass}
          />
              
        </div>
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Reset password'
          />
        </div>
      
        </form>
        {/* <div onClick={resendOtp}>
          <p className=' text-black text-center'>Didn’t get OTP? <button className=' text-primary-color cursor-pointer font-[500] text-[16px]' > Resend</button></p>
        </div> */}
         
          </div>
      
        </div>
    </div>
</div>
</div>
  )
}

export default ResetUserLogin