import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import Button2 from '../SelectValue/Button';
import Loading from '../SelectValue/Loading';
import getServices from '../../Services/get-services';
import postServices from '../../Services/post-services';
import { ToastContainer, toast } from 'react-toastify';


const steps = [
    {
        id:'1',
      label: 'Skill Group 1 - Leaner centricity',
      
    },
    {
        id:'2',
      label: 'Skill Group 2 -Teaching for recall',
      
    },
    {
        id:'3',
      label: 'Skill Group 3 - Leaner engagement',
     
    },
  ];

function NewAss() {
    const [assTitle, setAssTitle] = useState('')
    const [ques, setQues] = useState([])
    const [loading, setLoading] = useState(false)
    const theme = useTheme();
    const details = localStorage.getItem('userdetailss')
    const user =  JSON.parse(details)
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;
// State to hold selected responses for each step
const [responses, setResponses] = useState({});
  const handleNext = () => {
    const currentStepResponses = ques[activeStep]?.questionData?.map((question, index) => responses[`${question.id}-${index}`]);
    const hasUnansweredQuestions = currentStepResponses.some(response => !response);
  
    // If there are unanswered questions, prevent proceeding to the next step
    if (hasUnansweredQuestions) {
      // Optionally, you can display a message to the user indicating that they need to answer all questions
      alert('Please answer all questions before proceeding.');
      return;
    }
  
    // If all questions are answered, proceed to the next step
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  
  const handleSubmit = () => {
    const currentStepResponses = ques[activeStep]?.questionData?.map((question, index) => responses[`${question.id}-${index}`]);
    const hasUnansweredQuestions = currentStepResponses.some(response => !response);
  
    // If there are unanswered questions, prevent proceeding to the next step
    if (hasUnansweredQuestions) {
      // Optionally, you can display a message to the user indicating that they need to answer all questions
      alert('Please answer all questions before proceeding.');
      return;
    }
    if(assTitle.length < 2){
      alert('Type in course name to proceed');
      return;
    }
  
    const formattedResponses = [];

   // Loop through each key in the responses object
   for (const key in responses) {
    if (responses.hasOwnProperty(key)) {
        // Extract questionId and answerPoint from the key
        const [questionId, index] = key.split('-');
        let answerPoint = responses[key];

        // Find the corresponding question data and its parent group
        const questionGroup = ques.find(group => group.questionData.find(q => q.id === questionId));

        if (questionGroup) {
            // Find the corresponding question data
            const questionData = questionGroup.questionData.find(q => q.id === questionId);
            // Check if questionData and its group_id property exist
            if (questionData && questionData.group_id) {
                const groupId = questionData.group_id;
                // Check if groupId is LEA9985
                if (groupId === 'LEA9985') {
                    // Reverse the answerPoint
                    answerPoint = 4 - answerPoint;
                }
                // Push the formatted response object into the array
                formattedResponses.push({
                    questionId,
                    answerPoint,
                    groupId
                });
            }
        }
    }
}


  //   // Log the formatted responses array
    console.log(formattedResponses);
    const  data = {
      "teacherId" : user.teacherId,
      "assessmentTitle" : assTitle,
      "assessment" : formattedResponses
      }
      setLoading(true)
    postServices.answerAss(data).then(
      res =>{
        setLoading(false)
        if(res.data.statusCode === '200'){
            toast.success(res.data.message, {
                onClose: () => {
                  window.location.href = `/history/report/${res.data.result.assessment_id}`
                }
              })
        }else{
            setLoading(false)
            toast.error(res.data.message,
                )
        }
    }    
    ).catch((e) =>{
      
    })
  //  console.log(responses)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRadioChange = (questionId, index, value) => {
    const questionKey = `${questionId}-${index}`; // Create unique key
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionKey]: value, // Use unique key for each question
    }));
  };

  useEffect(() =>{
    setLoading(true)
    getServices.getQuestion().then(
     res =>{
        setLoading(false)
        if(res.data.statusCode === '200'){
            setQues(res.data.result)
        }
     }   
    ).catch(
        error =>{
            console.log(error)
        }
    )
}, [])



  return (
    <div >
        <Loading
          open={loading}
        />
         <ToastContainer
         position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
        <div className=' p-3 my-5 '>
        <div className=' grid sm:grid-cols-3 '>
        <div className=''>
       
        <label className=' block mb-3 text-black'>{'Enter a course title'} <Tooltip placement="top" title="It helps to think of a particular course that you teach while you answer the questions. Fill in that course name below." arrow><InfoIcon  className=' text-[#14013A]' /></Tooltip> </label>
        <input 
            className=' w-full bg-[#F7F8FB] text-black text-[16px]  outline-0 border border-gray-300 p-3 rounded-lg'
            placeholder={'e.g. Intro to computer systems'}
            required
            type={'type'}
            onChange={(e) => setAssTitle(e.target.value)}
            value={assTitle}
        />
       </div>
        
        </div>
        
            
        </div>
        <div>
        <h3 className=' mb-3 font-bold'> Rate how often you do these activities in your teaching:</h3>
        {/* <p className= 'mb-3 '>In your usual practice, how often do you do the following: <br></br>
          GUIDE: Never-0%, Rarely-25%, Sometimes-50%, Often-75%, Always-100% of the time</p> */}
        <Box className='  w-full overflow-x-auto rounded-xl overflow-scroll'>
     
          <div>
     
      <div className="overflow-x-auto rounded-xl overflow-scroll">
        <table className='table-auto  min-w-full'>
          <thead className=' '>
            <tr className=' bg-[#7A8699] rounded-xl text-white text-[14px] font-normal'>
              <th className='px-4 py-2'><p className='capitalize text-start text-[16px]'>
              {steps[activeStep].label}{' '}
             
            </p></th>
            <th className='px-4 py-2'>Never <br />0</th>
            <th className='px-4 py-2'>Rarely <br />1</th>
            <th className='px-4 py-2'>Sometimes <br />2</th>
            <th className='px-4 py-2'>Often <br />3</th>
            <th className='px-4 py-2'>Always <br />4</th>
            </tr>
          </thead>
          <tbody>
            {ques[activeStep]?.questionData?.map((question, index) => (
              <tr className=' bg-white' key={question.id}>
                <td className='border px-4 py-2'>{question.question}?</td>
                {[0, 1, 2, 3, 4].map((value) => (
                  <td key={value} className='border text-center pl-8 py-2 '>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        row
                        aria-label={`question-${index}`}
                        name={question.id}
                        value={responses[`${question.id}-${index}`] || ''}
                        onChange={(event) => handleRadioChange(question.id, index, event.target.value)}
                      >
                        <FormControlLabel
                          key={value}
                          value={value.toString()}
                          control={<Radio sx={{ '&.Mui-checked': { color: '#000F2C' } }} />}
                          label=''
                        />
                      </RadioGroup>
                    </FormControl>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
          <div>
            {activeStep + 1 === 3 && 
            <div className=' flex justify-end mt-3 mb-2'>
              <Button2
                name={'Submit'}
                onClick={handleSubmit}
              />
            </div>
            }
          </div>
          <div>
        <LinearProgress variant="determinate"  sx={{ backgroundColor:"#e1d5f9",  '& .MuiLinearProgress-bar': { backgroundColor: '#000e9C' } }} value={ ((  (activeStep + 1) /3 ) * 100)} />
        </div>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{ '& .MuiMobileStepper-dotActive': { backgroundColor: '#000F2C' } }}
        nextButton={
          <Button
            size="medium"
            sx={{color: 'white', p: '3', '&:hover': {
              backgroundColor: '#000F2C', // Specify your hover color here
            },  backgroundColor: '#000F2C', textTransform:'capitalize' ,
            ...(activeStep === maxSteps - 1 && { backgroundColor: 'white', color: 'white' }),
            }}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
           {`Next`.charAt(0).toUpperCase() + `Next`.slice(1)}
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="medium" sx={{color: '#000F2C',  
          backgroundColor:'#f1f5f9',
          textTransform:'capitalize' }} onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    <div>
      
    </div>
        </div>

    </div>
  )
}

export default NewAss