import React from 'react'
// import { NavLink, Outlet } from 'react-router-dom';
import NewAss from '../../Components/Assessment/NewAss';

function Assessment() {
  return (
    <div>
    {/* <div className='  flex bg-white gap-4'>
    <NavLink
      className={({isActive}) =>{
        return( ' block text-black p-3   pl-6 ' + (
         !isActive ? ' block ' : ' border-b-2 border-b-black text-black block'
           ))
             }}  
              to='newass'
               isActive={(match, location) => {
                  // Check if the current location matches exactly or partially the 'basicinfo' path
                      return location.pathname === '/assessment/newass' || location.pathname === '/assessment';
                    }}
                      >
       New assesements
       </NavLink>
       <NavLink
             className={({isActive}) =>{
                            return( ' block text-black p-3  pl-6 ' + (
                              !isActive ? ' block ' : '  border-b-2 border-b-black text-black block'
                            ))
                        }}  
                      to='passass'>
       Past assesements
       </NavLink>

    </div> */}
    <div className=' mt-2'>
      <NewAss/>
    </div>
    </div>
  )
}

export default Assessment