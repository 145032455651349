import React from 'react'
import Ava from '../../Images/pp.png'
import { NavLink, Outlet } from 'react-router-dom'
function Settings() {
  const details = localStorage.getItem('userdetailss')
    const user =  JSON.parse(details)
  return (
    <div className=' grid sm:grid-cols-3 gap-5'>
    <div className=''>
      <div className=' bg-white mb-5 rounded-lg p-4'>
        <img src={Ava} alt='Avater' className=' mb-4 mx-auto'/>
        <p className=' text-center mb-4 font-bold text-[#14013A] text-[16px]'>{user.firstName} {user.lastName}</p>
        <p className=' text-center mb-4 text-[#737C8A] text-[13px] font-[400]'>{user.email}</p>
        <p className=' text-center mb-6 text-[#737C8A] text-[13px] font-[400]'>{user.phoneNumber}</p>
        <p className=' text-center mb-6 text-[#737C8A] text-[13px] font-[400]'> Update your user profile for proper tracking</p>
      </div>
      <div className=' bg-white mb-5 rounded-lg p-4'>
      <NavLink
             className={({isActive}) =>{
                            return( ' block text-black p-3 mb-3 pl-6 ' + (
                              !isActive ? ' block ' : '  bg-[#F4F4F4] text-black block'
                            ))
                        }}  
                      to='basicinfo'
                      isActive={(match, location) => {
                      // Check if the current location matches exactly or partially the 'basicinfo' path
                      return location.pathname === '/settings/basicinfo' || location.pathname === '/settings';
                    }}
                      >
       Basic information
       </NavLink>
       <NavLink
             className={({isActive}) =>{
                            return( ' block text-black p-3 mb-3 pl-6 ' + (
                              !isActive ? ' block ' : '  bg-[#F4F4F4] text-black block'
                            ))
                        }}  
                      to='aboutme'>
       About me
       </NavLink>
       {/* <NavLink
             className={({isActive}) =>{
                            return( ' block text-black p-3 mb-3 pl-6 ' + (
                              !isActive ? ' block ' : '  bg-[#F4F4F4] text-black block'
                            ))
                        }}  
                      to='experience'>
       Experience
       </NavLink> */}
      </div>
    </div>

    <div className=' sm:col-span-2 sm:h-[45%]'>
      <Outlet/>
    </div>
    </div>
  )
}

export default Settings