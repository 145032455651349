import React, {useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import { Link } from 'react-router-dom'
import Loading from '../SelectValue/Loading'
// import postService from '../../Services/post-service'
import Logo from '../../Images/lecture.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import postServices from '../../Services/post-services';
// import Constant from '../../Constant'
function Loginform() {
  const [loading, setLoading] = useState(false)
    const initialValues = { 
        email: '',
        password: '',

      }
      const validationSchema = Yup.object({
        
      //  email:Yup
      //  .string()
      //  .email()
      //  .required('Please Enter your Email'),
       password: Yup.string()
       .required('Please Enter your password')
     
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setLoading(true)
        const {email, password} = values
        postServices.login(email, password).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.statusCode === '200'){
              if(response.data.result.isPasswordChange === '1'){
                localStorage.setItem('emaill',response.data.result.emailAddress )
                // localStorage.setItem('userdetailss', JSON.stringify(response.data.result))
                // localStorage.setItem('accountStatusss',response.data.result.emailAddress )
                return   window.location.href = '/resetadminuserpassword'
              } else{
                localStorage.setItem('userdetailss', JSON.stringify(response.data.result))
                localStorage.setItem('accountStatusss',response.data.result.emailAddress )
                
                return   window.location.href = '/dashboard'
              }
             
             }else{
            
              toast.error(response.data.message,
                )
             }
             
            },
            (error) => {
              setLoading(false)
                return  toast.error('Login failed. Try again',
                )
              }
        )
        // navigate('/dashboard')
      }

  return (
    <div className=' sm:w-[40%] mx-auto px-5 pt-10'>
    <Loading
      open={loading}
    />
    <ToastContainer/>
     <img src={Logo} className=" mx-auto mt-8 mb-5 "  alt=" logo" /> 
        <div className=' bg-white shadow-lg p-10 rounded-[16px]'>
       
            <p className=' mt-6 text-[18px] text-black mb-5 md:text-[24px] font-[600]'>Sign in</p>
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            
               <FormikControl
                control='input'
                label='Email address'
                name='email'
              />  
               
               <FormikControl
                control='password'
                label='Password'
                name='password'
              /> 
              <div className=' flex justify-end mt-3 mb-7'>
                <Link to='/forgetpassword'><span className=' text-red-700'>Forgot password?</span></Link>
              </div> 
              <div className='   sm:flex sm:justify-between mb-5 mt-6'>
              <Button
                name='Sign in'
              />
               <div className=' mt-3 sm:mt-0 flex flex-col justify-center'>
                <p className=' font-[500] text-primary-color1 text-center'>Don’t have an account? <Link to='/signup' className=' text-primary-color'>Sign up</Link> </p>
              </div>
              </div>
             
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default Loginform