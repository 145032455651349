import React, { useEffect, useState } from 'react'
import PassAssCom from './PassAssCom'
import getServices from '../../Services/get-services'
import Loading from '../SelectValue/Loading'
import Inputtt from '../SelectValue/Inputtt'

function PassAss() {
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const details = localStorage.getItem('userdetailss')
  const user =  JSON.parse(details)
  useEffect(() =>{
    setLoading(true)
    getServices.getPastHistory(user.teacherId).then(
     res =>{
        setLoading(false)
        if(res.data.statusCode === '200'){
            setHistory(res.data.result)
        }
     }   
    ).catch(
        error =>{
            console.log(error)
        }
    )
  },[user.teacherId])

  const filteredData = history.filter(
    person => {
      const searchLowerCase = search.toLowerCase();
      return (
        person
        .title
        .toLowerCase()
        .includes(searchLowerCase) 
      );
    }
  );
  return (
    <div>
    <Loading
      open={loading}
    />
    <div className=' sm:grid  sm:grid-cols-2'>
    <Inputtt
        placeholder={'Search by name'}
        onChange={setSearch}
      />
    </div>
        <div className=' grid md:grid-cols-3 gap-4 sm:grid-cols-2'>
        {filteredData.map((e,index) => {
          return <div key={index}>
          <PassAssCom
            name={e.title}
            date={e.inserted_dt.slice(0,-8)}
            time={e.inserted_dt.slice(-8)}
            link={`/history/report/${e.assessment_id}`}
            link2={`/history/pastques/${e.assessment_id}`}
          />
          </div>
        })}
         
        </div>

        {filteredData.length === 0 && !loading && <p className=' text-center font-bold text-red-700 mt-5'>No  History Found</p>}
    </div>
  )
}

export default PassAss