import React from 'react'
import Loginform from '../../Components/Login/LoginForm'

function Login() {
  return (
    <div>
        <Loginform/>
    </div>
  )
}

export default Login